<template>
  <div class="sphere">
    <div class="sphere__content container">
      <div class="sphere__items">
        <div class="sphere__items-title">
          <h1 class="font-32">Какие сферы прокачаем?</h1>
        </div>
        <div class="sphere__items-block">
          <div
            class="sphere__items-block-list"
            v-for="(item, idx) in list"
            :key="idx"
          >
            <div class="sphere__items-block-list-img">
              <img :style="imgStyles" :src="item.image" />
            </div>
            <div class="sphere__items-block-list-blur"></div>
            <div
              :style="`background-color:` + item.color + `;`"
              class="sphere__items-block-list-top"
            >
              <p class="font-28">{{ item.title }}</p>
            </div>
            
            <div class="sphere__items-block-list-text">
              <p class="font-16" v-html="item.text"></p>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          color: "#1985A1",
          title: "Физическая",
          image: require("@/assets/images/png/sphere5.png"),
          text: "Программы, меняющие жизнь. Раскрой силу, любовь и скрытые возможности своего тела с помощью наших тренировок.",
        },
        {
          color: "#A7A3BE",
          title: "Ментальная",
          image: require("@/assets/images/png/sphere4.png"),
          text: "Вебинары, меняющие сознание. Создай новую себя, отпустив негативные эмоции и заедание стресса. Обрети порядок и чистоту сознания.",
        },
        {
          color: "#579695",
          title: "Духовная",
          image: require("@/assets/images/png/sphere3.png"),
          text: "Медитации, меняющие энергию. Познай свои истинные желания, войдя в состояние потока и нескончаемого источника энергии.",
        },
      ],
    };
  },
  computed: {
    imgStyles() {
      return {
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 52.6%, #000000 100%)",
        position: "relative",
      };
    },
  },
};
</script>

<style></style>

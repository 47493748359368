<template>
    <div class="fitness" id="auth">
        <div class="fitness__all">
            <div class="fitness__content">
                <div class="fitness__items container">
                    <div class="fitness__items-text">
                        <span class="fitness__items-text-title font-40">Ведущая марафона и автор тренировок</span>
                        <span class="fitness__items-text-name font-32">Богенбаева Нурбота</span>
                        <span class="fitness__items-text-desc">Сертифицированный фитнес, EMS и Stretching тренер. 5-и
                            кратная чемпионка РК и 3-х кратная чемпионка международных соревнований стран центральной
                            Азии
                            по легкой атлетике. Имеет общий, 14 летний опыт в профессиональном спорте и в
                            фитнесе.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="fitness__all-swiper">
            <div class="fitness__all-swiper-items">
                <div class="fitness__all-swiper__item" v-for="t in title" :key="t">
                    <div class="fitness__all-swiper-items-title">
                        <span class="font-40" style="font-size: 40px!important">{{ t.count }}</span>
                    </div>
                    <div class="fitness__all-swiper-items-desc">
                        <span class="font-18">{{ t.text }}</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: [
                {
                    count: '14',
                    text: 'Лет опыта в профессиональном спорте и фитнесе',
                },
                {
                    count: '25 000+',
                    text: 'Женщин прошли авторскую программу Нурбы по всему СНГ',
                },
                {
                    count: '4+',
                    text: 'Более 4-х лет ведёт свои авторские фитнес курсы',
                },
                             

            ]
        }
    }

}
</script>

<style scoped>
</style>
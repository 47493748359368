import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
    // component: () => import(/* webpackChunkName: "about" */ '../views/Cooming.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: HomeView
    // component: () => import(/* webpackChunkName: "about" */ '../views/Cooming.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/public_offer',
    name: 'public_offer',
    component: () => import('../views/PublicOffer.vue')
  },
  {
    path: '/confidential',
    name: 'confidential',
    component: () => import('../views/Confidential.vue')
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/Payment.vue')
  },
  {
    path: '/personal-account',
    name: 'PersonalAccount',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: () => import('../views/SubscriptionsView.vue')
  },
  {
    path: '/payment/:id',
    name: 'Payment',
    component: () => import('../views/PayView.vue')
  },
  {
    path: '/pay/:id',
    name: 'paymentById',
    component: () => import('../views/PayViewV2.vue')
  },
  {
    path: '/tariff',
    name: 'Tariff',
    component: () => import('../views/TariffView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

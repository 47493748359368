<template>
  <div :class="open ? 'faq open' : 'faq'" @click="$emit('toggleOpen', index)">
    <div class="question font-20"><span v-html="faq.question"></span></div>
    <div class="answer font-16-14"><span v-html="faq.answer"></span></div>
  </div>
</template>

<script>
export default {
  name: "FAQs",
  props: ["faq", "index", "open"],
};
</script>
<style lang="scss" scoped>
.faqs {
  overflow: hidden;
}

.faq {
  background: #ffffff;
  display: block;
  width: 100%;
  margin: 2px auto;
  padding: 28px 24px;
  @media (max-width: 576px) {
    padding: 24px;
  }
  .question {
    position: relative;
    //    margin-left: 4%;
    // font-size: 18px !important;
    // font-weight: 300 !important;
    // transition: all 0.3s ease-in;
    // padding: 10px 0 10px 112px;
    display: flex;
    align-content: center;
    align-items: center;
    width: 90%;
    font-weight: 700;

    &:hover,
    &:focus {
      font-weight: 600 !important;
    }

    &::after {
      content: url("@/assets/images/svg/ic_right.svg");
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      @media (max-width: 576px) {
        right: -10%;
      }
    }
  }
  .open {
    .question {
      font-weight: 600 !important;
      transform: scale(1);
    }
  }
}

.faq.open .question::after {
  content: url("@/assets/images/svg/ic_right_up.svg");
  // transition: 400ms;
  // color: #D3D3D3;
  // font-size: 110px;
  // font-weight: 200;
  // padding: 9px 7px 7px 5px;
  // margin-right: 62px;
  // position: absolute;
}

.faq.open .answer {
  opacity: 1;
  max-height: 100%;
  padding: 5% 0;
}

.faq .answer {
  // padding-left: 112px;
  opacity: 0;
  max-height: 0px;
  overflow-y: hidden;
  // transition: all 0.4s ease-out;
}
</style>

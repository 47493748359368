<template>
  <div class="tab-items">
    <ul class="tab-list">
      <li v-for="tab in tabItems" :key="tab" class="tab-item">
        <div :class="{active: tab === selected}" class="tab-link second-title font-20" 
        @click="setTab(tab)
        ">{{ tab.title }}</div>
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  props: {
    tabItems: {
      type: Array,
      required: true
    },
    selected: {
      type: String,
      required: true
    }
  },
  methods: {
    setTab(tab){
      if(tab.id === 1){
        this.$emit('setTab',tab)
      }
    }
  }
}
</script>

<style scoped lang="scss">

.tab-items{
  position: relative;
}
.tab-list{
  display: flex;
  align-items: center;
  gap: 10px 30px;
  margin: 0;
  padding: 0;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  // overflow: scroll;
}

.tab-link{
  cursor: pointer;
  // background: #FFFFFF;
  color: #202020;

    // font-weight: 700;
    // font-size: 18px;
    // line-height: 167%;
  text-align: center;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  &:hover, &.active{
    color: #1D1D1D;
    font-weight: 700;
    // border-bottom: 1px solid #3252C6;
    // background: #00E191;
  }
}
@media (max-width: 576px) {
//   .tab-list{
//   flex-direction: column;
//   align-items: flex-start;
  
// }
.tab-item{
    margin: 1% 0;
  }
}
</style>
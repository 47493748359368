import * as validators from "@vuelidate/validators";
import * as Password from '@/vuelidate/Password.js';
import i18n from "@/plugins/i18n";

const { createI18nMessage } = validators;

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

export const required = withI18nMessage(validators.required);
export const minLength = withI18nMessage(validators.minLength, {
  withArguments: true,
});
export const maxLength = withI18nMessage(validators.maxLength(10));
export const sameAs  = withI18nMessage(validators.sameAs, {
  withArguments: true,
})
export const upperCase = withI18nMessage(Password.containsUppercase, {
    withArguments: false,
  });
export const lowerCase = withI18nMessage(Password.containsLowercase);
 export const isNumber = withI18nMessage(Password.containsNumber);
export const isSpecial = withI18nMessage(Password.containsSpecial);
export const minValue = withI18nMessage(validators.minValue, {
  withArguments: true,
});
export const email = withI18nMessage(validators.email);

<template>
    <div class="sliderin">
        <div class="sliderin__content">
            <div class="sliderin__items">
                <div class="sliderin__items-title ">
                    <div class="container">
                        <span class="font-32">Что входит в подписку Feels?</span>
                        <div class="sliderin__items__navigation">
                            <div class="swiper-button-next selectornx0"></div>
                            <div class="swiper-button-prev selectorpr0"></div>
                        </div>
                    </div>
                </div>
                <swiper :breakpoints="breakpoints" :centeredSlides="true" :loop="true" :modules="modules"
                  :slides-per-view="4" :space-between="30" :navigation="navigation0" class="sliderin__items-swiper inslide">
                    <swiper-slide class="inslide__items" v-for="item in includes" :key="item">
                        <div class="inslide__items-icon">
                            <img :src="item.img" alt="">
                        </div>
                        <div class="inslide__items-text">
                           <span class="inslide__items-text-title font-24" v-html="item.title"></span>
                           <span class="inslide__items-text-desc font-20" v-html="item.desc">

                           </span> 
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data(){
        return{
            includes: [
                {
                    id:1,
                    img: require('@/assets/images/png/in1.png'),
                    title: 'Программы питания',
                    desc: 'Программа состоит из недорогих и доступных продуктов, специально составленная совместно с профессиональным нутрициологом. Большой выбор вкусных и полезных рецептов.',
                },
                {
                    id:2,
                    img: require('@/assets/images/png/in2.png'),
                    title: 'Онлайн тренировки',
                    desc: 'Вам регулярно открывается доступ  к полноценным видео тренировкам длительностью 20-40 минут, которые можно выполнять в любом месте и в любое время. Просто включайте видео и повторяйте за тренером.',
                },
                {
                    id:5,
                    img: require('@/assets/images/png/in5.png'),
                    title: 'Медитации',
                    desc: 'Авторские медитации, для полного ментального расслабления и активации энергии в физическом теле.',
                },
                {
                    id:3,
                    img: require('@/assets/images/png/in3.png'),
                    title: 'Поддержка и мотивация',
                    desc: 'Вы попадаете в фитнес-комьюнити, где вас ждёт живое общение в чате с единомышленницами, дружеская атмосфера, ответы на все вопросы, поддержка и мотивация. Вместе у нас все получится!',
                },
                {
                    id:4,
                    img: require('@/assets/images/png/in4.png'),
                    title: 'Обучающие курсы',
                    desc: 'Уникальная программа по налаживанию отношений с едой, пищевых зависимостей и эмоционального переедания. Мы поможем навсегда забыть о диетах, срывах и чувстве вины за съеденное.',
                },
              
            ],
            navigation0: {
                nextEl: '.selectornx0',
                prevEl: '.selectorpr0',
            },
            breakpoints: {
                // when window width is >= 320px
                0: {
                    slidesPerView: 1.1,
                },
                // when window width is >= 480px
                655: {
                    slidesPerView: 2.1,
                    spaceBetween: 10
                },
                // when window width is >= 640px
                1000: {
                    slidesPerView: 2.4,
                },
                1300: {
                    slidesPerView: 3.4
                    // spaceBetween: 20
                }
            },
        }
    }

}
</script>

<style>
</style>
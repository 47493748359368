<template>
  <div class="auth">
    <div class="auth__content">
      <div v-if="register" class="auth__items">
        <div class="auth__items-title">
          <span class="font-24">Создать аккаунт</span>
          <span class="font-16"
            ><p>Уже есть аккаунт?</p>
            <button @click="register = !register">Войти</button></span
          >
        </div>
        <form
          onsubmit="return false"
          @submit="submitRegister()"
          class="auth__items-form"
        >
          <div class="basicInputA">
            <input
              type="email"
              name="email"
              autocomplete="off"
              v-model="signup.email"
              required
            />
            <label for="email" class="label-name">
              <span class="content-name">Электронная почта</span>
            </label>
          </div>
          <div class="basicInputA">
            <input
              type="text"
              name="name"
              autocomplete="off"
              v-model="signup.name"
              required
            />
            <label for="name" class="label-name">
              <span class="content-name">Имя</span>
            </label>
          </div>
          <div class="basicInputA">
            <input
              type="password"
              name="password"
              autocomplete="off"
              v-model="signup.password"
              required
            />
            <label for="password" class="label-name">
              <span class="content-name">Пароль</span>
            </label>
          </div>
          <div class="basicInputA">
            <input
              type="password"
              name="confirm_password"
              autocomplete="off"
              v-model="signup.confirm_passwword"
              required
            />
            <label for="confirm_password" class="label-name">
              <span class="content-name">Подтвердить пароль</span>
            </label>
          </div>
          <div class="auth__items-form-terms">
            <span class="font-16"
              >Нажимая «Создать учетную запись», я подтверждаю, что ознакомился
              с положениями, указанными в статьях
              <router-link to="">Условия использования</router-link> и
              <router-link to="">Политика конфиденциальности</router-link
              >.</span
            >
          </div>
          <div class="auth__items-form-button">
            <button class="btn_green font-16" type="submit">
              Создать аккаунт
            </button>
          </div>
        </form>
      </div>
      <div v-else class="auth__items">
        <div class="auth__items-title">
          <span class="font-24">Войти</span>
          <span class="font-16"
            ><p>Новый пользовалеть?</p>
            <button @click="register = !register">Создать аккаунт</button></span
          >
        </div>
        <form
          onsubmit="return false"
          @submit="Login()"
          class="auth__items-form"
        >
          <div class="basicInputA">
            <input
              type="email"
              name="email"
              autocomplete="off"
              v-model="signin.email"
              required
            />
            <label for="email" class="label-name">
              <span class="content-name">Электронная почта</span>
            </label>
          </div>
          <div class="basicInputA">
            <input
              type="password"
              name="password"
              autocomplete="off"
              v-model="signin.password"
              required
            />
            <label for="password" class="label-name">
              <span class="content-name">Пароль</span>
            </label>
          </div>
          <!-- <div class="auth__items-form-forget">
            <button type="button" class="font-14">Забыли пароль?</button>
          </div> -->
          <div class="auth__items-form-button">
            <button type="submit" class="btn_green font-16">Войти</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minLength,
  upperCase,
  lowerCase,
  isNumber,
  isSpecial,
} from "@/plugins/i18n-validators";
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  setup: () => ({ v$: useVuelidate(), toast: useToast() }),
  data: () => ({
    register: false,
    signin: {
      email: null,
      password: null,
    },
    signup: {
      email: null,
      name: null,
      password: null,
      confirm_passwword: null,
    },
  }),
  methods: {
    ...mapMutations(["SET_MODAL_LOGIN"]),
    Login() {
      axios
        .post("token/", {
          email: this.signin.email,
          password: this.signin.password,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$cookies.set("token", response.data.access, "1D");
            this.SET_MODAL_LOGIN();
            this.toast.success("Вы упешно вошли");
            this.$router.push({ name: "PersonalAccount" });
          } else {
            console.log(response);
          }
        })
        .catch((e) => {
          console.log(e);
          this.toast.error(e.response.data?.description);
        });
    },
    submitRegister() {
      axios
        .post("users/register/", {

          email: this.signup.email,
          first_name: this.signup.name,
          password1: this.signup.password,
          password2: this.signup.confirm_passwword,
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            this.$cookies.set("token", response.data.access, "1D");
            this.SET_MODAL_LOGIN();
            this.toast.success("Вы упешно зарегестрировались");
            this.$router.push({ name: "PersonalAccount" });
            this.register = false
          } else {
            console.log(response);
          }
        })
        .catch((e) => {
          console.log(e);
          this.toast.error(e.response.data?.description);
        });
    },
  },
};
</script>

<style></style>

<template>
  <div class="maraphone" id="programs">
    <div class="maraphone__content">
      <div class="maraphone__items">
        <div class="maraphone__items-title">
          <div class="container">
            <span class="font-32">Программы тренировок</span>
            <div class="maraphone__items__navigation">
              <div class="swiper-button-next selectornx1"></div>
              <div class="swiper-button-prev selectorpr1"></div>
            </div>
          </div>
        </div>
        <swiper
          :breakpoints="breakpoints"
          :centeredSlides="true" :loop="true"
          :modules="modules"
          :slides-per-view="4"
          :space-between="30"
          :navigation="navigation1"
          class="maraphone__items-swipers slid"
        >
          <swiper-slide class="slid__items" v-for="a in Maraphone" :key="a.id">
            <div class="slid__items-icon">
              <img :src="a.img" alt="" />
            </div>
            <div class="slid__items-text" :class="{ active: isOpen[a.id] }">
              <div class="slid__items-text-content">
                <div class="slid__items-text-content-title">
                  <span class="font-24">{{ a.title }}</span>
                  <button @click="showMore(isOpen[a.id], a.id)">
                    <img
                      v-if="isOpen[a.id]"
                      src="@/assets/images/svg/ic_top.svg"
                      alt=""
                    /><img
                      v-else
                      src="@/assets/images/svg/ic_back.svg"
                      alt=""
                    />
                  </button>
                </div>
                <div class="slid__items-text-content-list">
                  <span class="list" v-html="a.text"></span>
                  <div class="slid__items-text-content-flex infotext">
                    <p
                      class="infotext__item"
                      v-for="(item, index) in a.tags"
                      :key="index"
                    >
                      <!-- <img src="@/assets/images/svg/kovrik.svg" alt=""> -->
                      {{ item.text }}
                    </p>
                    <!-- <p class="infotext__item"><img src="@/assets/images/svg/stulya.svg" alt="">Стул,
                                        диван</p> -->
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);
import axios from "axios";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isOpen: [false],
      text: "<p><ul><li>Добиться рельефного тела в домашних условиях</li><li>Проработать диастаз, отечность и зостоявшийся целлюлит</li><li>Укрепить обвисшие и слабые мышцы пресса и живота</li><li>Подтянуть и придать упругость ягодицам</li><li>Сбросить лишний вес и укрепить мышечную массу</li><li>Повысить выносливость</li></ul></p>",
      dataResText: null,
      Maraphone: [
        {
          id: 1,
          img: require("@/assets/images/png/programs/pr1.png"),
          title: "Рельеф 1.0",
          text: "<p>Данная программа тренировок предназначена для похудения и проработки рельефа всего тела. С программой «Рельеф 1.0» ты сможешь всего за 21 день сбросить лишний вес, просушить все тело, придать прессу рельефность и упругость ягодицам.</p>",
          tags: [
            {
              img: "",
              text: "Рельеф тела",
            },
            {
              img: "",
              text: "Похудение",
            },
            {
              img: "",
              text: "Плоский живот",
            },
            {
              img: "",
              text: "Антицеллюлит",
            },
          ],
        },
        {
          id: 2,
          img: require("@/assets/images/png/programs/pr2.png"),
          title: "Рельеф 2.0",
          text: "<p>Данная программа акцентирована на проработку мышц всего тела в домашних условиях. Благодаря авторской системе тренировок «Рельеф 2.0» за короткий срок ты сможешь сбросить лишний вес, набрать мышечную массу и повысить  выносливость. Программа поможет придать рельеф мышцам пресса и упругость ягодицам.</p>",
          tags: [
            {
              img: "",
              text: "Рельеф тела",
            },
            {
              img: "",
              text: "Сушка",
            },
            {
              img: "",
              text: "Похудение",
            },
            {
              img: "",
              text: "Устранение целлюлита",
            },
          ],
        },
        {
          id: 3,
          img: require("@/assets/images/png/programs/pr3.png"),
          title: "Гибкость",
          text: "<p>Данная программа тренировок основана на элементах йоги, пилатеса и стретчинга. Всего за три недели ты получаешь возможность улучшить осанку, сесть на шпагат и развить подвижность всего тела. Занятия растяжкой являются идеальным дополнением к любым другим тренировочным программам.</p>",
          tags: [
            {
              img: "",
              text: "Без инвенторя",
            },
            {
              img: "",
              text: "Шпагат",
            },
            {
              img: "",
              text: "Новичок",
            },
            {
              img: "",
              text: "Здоровая осанка",
            },
          ],
        },
        {
          id: 9,
          img: require("@/assets/images/png/programs/pr9.png"),
          title: "МФР Массаж",
          text: "<p>Данная программа предназначена для проработки и расслабления разных слоев мышц и фасций, улучшения кровообращения и питания тканей, снятие спазмов, а также повышения мобильности и гибкости суставов. МФР самомассаж уменьшает болевые ощущения в мышца и ускоряет их восстановление.</p>",
          tags: [
            {
              img: "",
              text: "Антицеллюлит",
            },
            {
              img: "",
              text: "Расслабление мышц",
            },
            {
              img: "",
              text: "Устранение отеков",
            },
            {
              img: "",
              text: "МФР",
            },
          ],
        },
        {
          id: 10,
          img: require("@/assets/images/png/programs/pr10.png"),
          title: "Гимнастика для беременных",
          text: "<p>Данный комплекс упражнений направлен как на укрепление, так и на расслабление мышц всего тела, в том числе на поддержание мышц спины, укрепление позвоночника, брюшного пресса, и подготовку мышц тазового дна. Регулярно выполняя данный комплекс упражнений, вы облегчите протекание беременности, подготовите свое тело к легким родам, также улучшите кровообращение и насытите плод кислородом</p>",
          tags: [
            {
              img: "",
              text: "Здоровая спина",
            },
            {
              img: "",
              text: "Расслабление",
            },
            {
              img: "",
              text: "Беременность",
            },
            {
              img: "",
              text: "МФР",
            },
          ],
        },
        {
          id: 11,
          img: require("@/assets/images/png/programs/pr11.png"),
          title: "Здоровая спина",
          text: "<p>Данная программа тренировок направлена на укрепление мышц спины, формирование здоровой осанки и снятие зажимов в шейном, грудном и поясничном отделах позвоночника. Благодаря программе 'Здоровая спина'  ты сможешь избавиться от болей в спине и в голове, получить красивую осанку, и ощущение легкости в теле.</p>",
          tags: [
            {
              img: "",
              text: "Устранение холки",
            },
            {
              img: "",
              text: "Здоровая спина",
            },
            {
              img: "",
              text: "Красивая осанка",
            },
            {
              img: "",
              text: "Сутулость",
            },
          ],
        },
      
        {
          id: 4,
          img: require("@/assets/images/png/programs/pr4.png"),
          title: "Full Body",
          text: '<p>Данная программа поможет за 21 день подсушить все тело, избавиться от отеков и повысить выносливость твоего организма. "Full Body" - состоит из высокоинтенсивных, функциональных упражнений, которые выполняются по круговой системе тренировок. Запусти жиросжигательные процессы по всему телу, устрани целлюлит и отечность с программой Full Body.</p>',
          tags: [
            {
              img: "",
              text: "Жиросжигание",
            },
            {
              img: "",
              text: "Сушка тела,",
            },
            {
              img: "",
              text: "Кардио",
            },
            {
              img: "",
              text: "Выносливость",
            },
          ],
        },
        {
          id: 5,
          img: require("@/assets/images/png/programs/pr5.png"),
          title: "Идеальный пресс",
          text: '<p>Данная программа тренировок разработана для глубокой проработки мышц пресса, КОРа и спины. Программа «Идеальный пресс» научит вас правильно дышать, контролировать пресс и КОР, чтобы они включились в работу и придали мышцам живота рельеф.</p>',
          tags: [
            {
              img: "",
              text: "Плоский живот",
            },
            {
              img: "",
              text: "Рельефный пресс",
            },
            {
              img: "",
              text: "Тренировки на КОР",
            },
          ],
        },
        {
          id: 6,
          img: require("@/assets/images/png/programs/pr6.jpg"),
          title: "Талия мечты",
          text: '<p>Данная программа тренировок разработана для глубокой проработки мышц пресса и КОРа. Программа отлично подойдет для восстановления после родов, при наличии диастаза (расхождения прямых мышц живота) и поможет сделать твою талию выразительной, а мышцы пресса рельефными.</p>',
          tags: [
            {
              img: "",
              text: "Плоский живот",
            },
            {
              img: "",
              text: "Рельефный пресс",
            },
            {
              img: "",
              text: "Идеальная талия",
            },
          ],
        },
        {
          id: 7,
          img: require("@/assets/images/png/programs/pr7.jpg"),
          title: "Сочные ягодицы",
          text: '<p>Данная программа акцентирована на проработку мышц ног и ягодиц. Благодаря авторской системе тренировок курса “Упругие ягодицы” ты сможешь сбросить лишний вес, набрать мышечную массу и повысить выносливость. Программа поможет тебе создать подтянутые ноги и упругие ягодицы в домашних условиях.</p>',
          tags: [
            {
              img: "",
              text: "Похудение",
            },
            {
              img: "",
              text: "Устранение целлюлита",
            },
            {
              img: "",
              text: "Упругие ягодицы",
            },
            {
              img: "",
              text: "Стройные ноги",
            },
          ],
        },
        {
          id: 8,
          img: require("@/assets/images/png/programs/pr8.jpg"),
          title: "Рельефные руки",
          text: '<p>Данная программа тренировок поможет тебе в создании подтянутых рук, красивой спины и в формировании здоровой осанки. Благодаря программе тренировок “Рельефные руки” ты сможешь подтянуть бицепс, трицепс и плечи, обеспечивая пропорциональное развитие всего своего тела.</p>',
          tags: [
            {
              img: "",
              text: "Рельефные руки",
            },
            {
              img: "",
              text: "Подтянутое тело",
            },
            {
              img: "",
              text: "Рельеф",
            },
          ],
        },
        {
          id: 12,
          img: require("@/assets/images/png/programs/pr12.png"),
          title: "МФР лица от отёчности",
          text: "<p>Данный комплекс упражнений по самомассажу лица поможет Вам поддерживать тонус лица и снять отечность всего за 15 минут в день Пработать диастаз, отечность и застоявшийся целлюлит Укрепить обвисшие и слабые мышцы пресса и живота Подтянуть и придать упругость ягодицам Сбросить лишний вес и укрепить мышечную массу</p>",
          tags: [
            {
              img: "",
              text: "Без инвентаря",
            },
            {
              img: "",
              text: "Массаж лица",
            },
            {
              img: "",
              text: "МФР",
            },
            {
              img: "",
              text: "Устранение отеков",
            },
          ],
        },
      ],
      navigation1: {
        nextEl: ".selectornx1",
        prevEl: ".selectorpr1",
      },
      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 1.2,
          paceBetween: 5,
        },
        // when window width is >= 480px
        500: {
          slidesPerView: 1.5,
          spaceBetween: 10,
        },
        655: {
          slidesPerView: 2.1,
          spaceBetween: 10,
        },
        // when window width is >= 640px
        1000: {
          slidesPerView: 2.4,
          spaceBetween: 20,
        },
        1300: {
          slidesPerView: 3.4,
          spaceBetween: 20,
        },
        1500: {
          slidesPerView: 3.4,
          spaceBetween: 40,
        },
        1600: {
          slidesPerView: 4.4,
          spaceBetween: 20,
        },
        1700: {
          slidesPerView: 4.4,
          spaceBetween: 40,
        },
      },
    };
  },
  created(){
    this.FETCH_MARAPHONE();
  },
  methods: {
    showMore(value, index) {
      // this.isOpen = !this.isOpen
      value = !value;
      this.isOpen[index] = value;
      this.$nextTick(this.$forceUpdate);
    },
    async FETCH_MARAPHONE() {
      await axios.get("programs/").then((response) => {
        this.dataResText = response.data;
      });
    },
  },
};
</script>

<style></style>

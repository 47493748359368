<template>
  <div class="online">
    <div class="online__content container">
      <div class="online__items">
        <div class="online__items-title">
          <h1 class="font-32">Преимущества <strong>онлайн-тренировок</strong></h1>
        </div>
        <div class="online__items-cards">
          <div
            class="online__items-cards-item"
            v-for="(item, idx) in onlineData"
            :key="'asd' + idx"
          >
          <img :src="item.image" />
          <div class="online__items-cards-item-title"><p class="font-18">{{ item.title }}</p></div>
          <div class="online__items-cards-item-text">
            <p class="font-16">{{ item.text }}</p>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      onlineData: [
        {
          title: "Доступность",
          image: require("@/assets/images/svg/online1.svg"),
          text: "Можно заниматься из любой точки мира и не тратить время на дорогу",
        },
        {
          title: "Время на себя",
          image: require("@/assets/images/svg/online2.svg"),
          text: "Тренировки в любое удобное и подходящее для вас время",
        },
        {
          title: "Есть выбор",
          image: require("@/assets/images/svg/online3.svg"),
          text: "Похудеть, набрать массу или всегда оставаться в форме — выбирай то, что подходит именно вам!",
        },
        {
          title: "Бережем время",
          image: require("@/assets/images/svg/online4.svg"),
          text: "30 минут Feels = 2,5 часа в зале. Столько обычно тратят в зале и дорогу до него.",
        },
      ],
    };
  },
};
</script>

<style></style>

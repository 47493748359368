<template>
  <div class="v2feedback" id="feedback">
    <div class="v2feedback__content container">
      <div class="v2feedback__items">
        <div class="v2feedback__items-row row">
          <div class="v2feedback__items-row-col1 col">
            <div class="v2feedback__items-row-col1-top">
              <span class="font-40">Начни заниматься прямо сейчас!</span>
              <img class="m__none" src="@/assets/images/png/girlzhatyr.png" alt="" />
              <span class="font-16"
                >Оформи подписку и получи мгновенный доступ ко всем программам и тренировкам!</span
              >
            </div>
            <div class="v2feedback__items-row-col1-buttom">
                <router-link :to="{name: 'Tariff'}" class="font-16 btn-white">НАЧАТЬ ТРЕНИРОВАТЬСЯ</router-link> 
                <span class="font-12">Нажимая на кнопку, я соглашаюсь на обработку <br/>  <router-link :to="{ name: 'confidential' }">персональных данных</router-link> и с  <router-link :to="{ name: 'public_offer' }">правилами пользования</router-link>.</span>
            </div>
          </div>
          <div class="v2feedback__items-row-col2 col d__none">
            <img src="@/assets/images/png/girlzhatyr.png" alt="" />
            <!-- <input placeholder="Ваше имя" class="input_wht" />
                      <input placeholder="Ваша почта" class="input_wht" />
                      <input placeholder="Ваш номер телефона" class="input_wht" />
                      <p class="font-14">Отправляя данные вы принимаете условия пользования</p>
                      <button class="btn_blue font-16-14">Получить тренировку бесплатно</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  data: () => ({

  }),
  computed: {
    ...mapGetters({auth: "GET_IS_AUTH"})
  },
  methods:{
    ...mapMutations(["SET_MODAL_LOGIN"]),
    toLogin() {
      if(this.auth){
        this.$router.push({ name: "PersonalAccount" });
      } else {
        this.SET_MODAL_LOGIN();
      }
    },
  }
};
</script>

<style></style>

<template>
    <div class="faq" id="faq">
        <div class="faq__content container">
            <div class="faq__items ">
                <div class="faq__items-row row">
                    <div class="faq__items-row-col4 col-3">
                        <span class="font-40">FAQ</span>
                        <span class="font-16">Ответим на все ваши вопросы</span>
                    </div>
                    <div class="faq__items-row-col6 col-7">
                        <div class="faqs">
                            <FAQs v-for="(faq, i) in faqs" :faq="faq" :index="i" :key="i" :open="faq.open"
                                @toggleOpen="toggleOpen" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>

<script>
import FAQs from '@/components/accordion/FAQ.vue';
export default {
    components: {
        FAQs
    },
    data() {
        return {
            open: false,
            question: [],
            faqs: [
                {
                    question: 'Есть ли противопоказания?',
                    answer: "- Беременность <br/> - Менее 8 недель после операций, кесарева или естественных родов <br/> - Злокачественные опухоли или доброкачественные опухоли в динамике роста  <br/> - Выпадение прямой кишки <br/> - Выпадение узлов <br/> - Паховая грыжа <br/> - Тяжелые степени болезней позвоночника <br/> - Любые острые воспалительные процессы в животе и мочеполовой системе <br/>- Кровоточащие миомы <br/>- Опущение матки 3-4 степени или выпадение <br/>- Грыжа позвоночника 3-4 степени <br/>- Незажившие мышечные разрывы и травмы<br/><br/>Важно перед началом занятий проконсультироваться со специалистом, чтобы понять, есть ли какие-либо ограничения и противопоказания.",
                },
                {
                    question: 'Если я своевременно не воспользовалась подпиской?',
                    answer: "Рекомендуем оформить подписку, как будете полностью готовы. Возврат не осуществляется.",
                },
                {
                    question: 'Можно ли приобрести подписку в подарок (сестре, подруге и тд)?',
                    answer: "Да конечно. Для этого нужно перейти к менеджеру, нажав на иконку в правом нижнем углу.",
                },
                {
                    question: 'Как будут проходить тренировки?',
                    answer: "В приложении перед стартом, выбираете удобное для Вас расписание. Тренировки от 30 мин до 1 часа (в зависимости от программы). ",
                },
                {
                    question: 'Будет ли обратная связь?',
                    answer: "Да, в приложении есть ссылка в “Телеграмм чат” с кураторами.",
                },
                {
                    question: 'Могу ли я отменить подписку?',
                    answer: "Да, в любой момент можно отменить подписку. При отмене подписки все будущие платежи, связанные с подпиской, будут отменены.",
                },
                {
                    question: 'Будет ли моя подписка продлеваться автоматически?',
                    answer: "Да, для удобства подписка продлевается автоматически.",
                },
                {
                    question: 'Будет ли меняться стоимость подписки?',
                    answer: "Подписка будет продлеваться автоматически по стандартной цене, которую Вы выбрали изначально.",
                },

            ],
        }
    },
    methods: {
        toggleOpen: function (index) {
            this.faqs = this.faqs.map((faq, i) => {
                if (index === i) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }
                return faq;
            });
        },
    }
}
</script>

<style></style>
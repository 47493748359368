import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './assets/styles/main.scss';
import "swiper/css";
import 'swiper/scss/navigation';
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/grid";
import axios from 'axios'
import i18n from '@/plugins/i18n'
import VueTheMask from 'vue-the-mask'
import VueCookies from 'vue-cookies'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const lang = localStorage.getItem('lang') || 'ru';
// axios.defaults.baseURL = 'https://dev.back.feels.kz/api/';

axios.defaults.baseURL = 'https://back.feels.kz/api/';
// axios.defaults.headers = {
//     Authorization: `Bearer ${$cookies.get("token")}`
//   }

// axios.defaults.headers.get['Access-Control-Allow-Credentials'] = true;
// axios.defaults.headers.get['access-control-allow-methods'] = 'PUT, GET, HEAD, POST, DELETE, OPTIONS';
// axios.defaults.headers.get['Content-Type'] ='application/json;charset=utf-8';
// axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
// axios.defaults.params = {
//     'lang': lang,
//   };
const options = {
    position: "top-center",
    timeout: 2000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    hideProgressBar: true,
    closeButton: false,
    icon: false,
    rtl: false
  };

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueCookies, { expires: '7d'})
    .use(Toast, options)
    .use(VueTheMask)
    // .use(VueMeta)
    
    .mount('#app')

<template>
  <div class="resv2">
    <div class="resv2__content">
      <div class="resv2__items">
        <div class="resv2__items-title container">
          <div class="resv2__items-title-basic">
            <span class="font-32">Результаты тех, кто занимается с нами</span>
            <span class="font-16"
              >Без диет и ограничений, с эффективными тренировками и в <br/> дружеской обстановке. 
            </span>
          </div>
          <div class="resv2__items__navigation">
            <div class="swiper-button-next selectornx2"></div>
            <div class="swiper-button-prev selectorpr2"></div>
          </div>
        </div>
        <div v-if="dataResAll" class="resv2__items-swiper">
          <swiper
            :slidesPerView="4"
            :centeredSlides="true" :loop="true"
            :breakpoints="breakpoints"
            :spaceBetween="32"
            :modules="modules"
            :navigation="navigation2"
            class="resv2__items-swiper-swipers"
          >
            <swiper-slide
              style="margin-top: 0 !important"
              class="resv2__items-swiper-swipers-slide"
              v-for="(r, index) in newRes"
              :key="index"
            >
              <div
                class="resv2__items-swiper-swipers-slide-img"
                v-if="r.img"
              >
                <img :src="r.img" />
              </div>
              <div
                class="resv2__items-swiper-swipers-slide-text"
                v-if="r.text"
              >
                <div
                  class="bb12"
                  :style="`background-color: ` + r.color + ` !important;`"
                >
                  <img src="@/assets/images/svg/asiiu.svg" />
                  <span>{{ r.text }}</span>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Grid, Navigation } from "swiper";
import "swiper/css/grid";
SwiperCore.use([Navigation, Grid]);
import axios from "axios";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      dataResText: null,
      dataResPhoto: null,
      dataResAll: [],
      newRes: [
        {
          img: require("@/assets/images/png/result/rs1.jpeg"),
          title: "",
        },
        {
          img: require("@/assets/images/png/result/rs2.jpeg"),
          title: "",
        },
        {
          img: require("@/assets/images/png/result/rs3.jpeg"),
          title: "",
        },
        {
          img: require("@/assets/images/png/result/rs4.jpeg"),
          title: "",
        },
        {
          img: require("@/assets/images/png/result/rs5.jpeg"),
          title: "",
        },
        {
          img: require("@/assets/images/png/result/rs6.jpeg"),
          title: "",
        },
        {
          img: require("@/assets/images/png/result/rs7.jpeg"),
          title: "",
        },
        {
          img: require("@/assets/images/png/result/rs8.jpeg"),
          title: "",
        },
        {
          img: require("@/assets/images/png/result/rs9.jpeg"),
          title: "",
        },
        {
          img: require("@/assets/images/png/result/rs10.jpeg"),
          title: "",
        },
        {
          img: require("@/assets/images/png/result/rs11.jpeg"),
          title: "",
        },
        {
          img: require("@/assets/images/png/result/rs12.jpeg"),
          title: "",
        },
        {
          img: require("@/assets/images/png/result/rs13.jpeg"),
          title: "",
        },
      ],
      res: [
        {
          img: require("@/assets/images/png/photo1.png"),
          title: "",
        },
        {
          img: null,
          title:
            "Нурба, с благодарностью за эфир, только недавно посмотрела, я смотрела и у меня слезы наворачивались,  если честно когда вступала в марафон, были мысли такая молоденькая девочка, что она может дать, в свои 35 я только иду к осознанности, а Вы в свои годы такая осознанная, такие мысли, и такой сильных дух в таком хрупком и красивом теле, еще раз благодарю и еще раз убеждаюсь и рада, что я в этом марафоне.",
          color: "#F3FBFB",
        },
        {
          img: require("@/assets/images/png/photo2.png"),
          title: "",
        },
        {
          img: null,
          title:
            "Добрый день, Нурбота! Хочу сказать вам спасибо за марафон рельеф 2.0 :) Думала результаты бывают только у тех, кто хочет похудеть. Но нет, на самом деле результаты можно получить чисто на упражнениях и без питания, я его вообще не придерживалась, о чем жалею, кстати. Думаю результат был бы лучше 😏. Спасибо еще раз за комфортные и неизнуряющие тренировки!",
          color: "#F3FBFB  ",
        },
        {
          img: require("@/assets/images/png/photo3.png"),
          title: "",
        },
        {
          img: null,
          title:
            "Добрый день, Нурбота! Хочу сказать вам спасибо за марафон рельеф 2.0 :) Думала результаты бывают только у тех, кто хочет похудеть. Но нет, на самом деле результаты можно получить чисто на упражнениях и без питания, я его вообще не придерживалась, о чем жалею, кстати. Думаю результат был бы лучше 😏. Спасибо еще раз за комфортные и неизнуряющие тренировки!",
          color: "#EEF4FA",
        },
        {
          img: require("@/assets/images/png/photo3.png"),
          title: "",
        },
        {
          img: null,
          title:
            "Добрый день, Нурбота! Хочу сказать вам спасибо за марафон рельеф 2.0 :) Думала результаты бывают только у тех, кто хочет похудеть. Но нет, на самом деле результаты можно получить чисто на упражнениях и без питания, я его вообще не придерживалась, о чем жалею, кстати. Думаю результат был бы лучше 😏. Спасибо еще раз за комфортные и неизнуряющие тренировки!",
          color: "#EEF4FA",
        },
      ],
      navigation2: {
        nextEl: ".selectornx2",
        prevEl: ".selectorpr2",
      },
      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 2.1,
          spaceBetween: 10,
        },
        // when window width is >= 480px
        655: {
          slidesPerView: 2.4,
          spaceBetween: 10,
        },
        // when window width is >= 640px
        1000: {
          slidesPerView: 3.4,
          spaceBetween: 32,
        },
        1300: {
          slidesPerView: 4.4,
          spaceBetween: 32,
        },
      },
    };
  },
  mounted() {
    this.fetchResultText();
  },
  methods: {
    async fetchResultText() {
      await axios
        .get("competitions/reviews/text/")
        .then((response) => (this.dataResText = response.data));

      await axios
        .get("competitions/reviews/photo/")
        .then((response) => (this.dataResPhoto = response.data));
      this.dataResText.map((item, i) => {
        this.dataResAll.push(this.dataResText[i], this.dataResPhoto[i]);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.swiper-slide.result__items-swiper-swipers-slide.swiper-slide-next {
  margin-top: 0 !important;
}

.swiper-slide.result__items-swiper-swipers-slide {
  margin-top: 0 !important;
}
</style>

<template>
  <div class="tariffes" id="tariffes">
    <div class="tariffes__content container" v-if="tariffesData">
      <div class="tariffes__items">
        <div class="tariffes__items-title">
          <span class="font-32">Планы подписок</span>
          <!-- <TabItem
            :tabItems="tariffesData"
            :selected="selected"
            @setTab="selectTab"
          ></TabItem> -->
        </div>
        <div class="tariffes__items-block">
          <div class="tariffes__items-block-full" v-for="(item, index) in tariffesData" :key="index">
            <div class="tcontent">
              <div class="tcontent__title">
                <span class="font-24" v-html="item.title"></span>
              </div>
              <div class="tcontent__price">
                <div class="tcontent__price-new font-32" v-if="item.data[0].price">
                  {{ formatPrice(item.data[0].price) }} ₸
                </div>
                <span class="tcontent__price-old font-20" v-if="item.data[0].old_price">{{
      formatPrice(item.data[0].old_price) }} ₸</span>
              </div>
              <div class="tcontent__line" :class="{ removeWidth: isActive[index] }">
                <div v-for="(item2, idx) in item.data[0].descriptions" :key="'d' + idx"
                  class="tcontent__line-title font-16">
                  <div class="tcontent__line-title-left">
                    <p class="font-16">{{ item2.title }}</p>
                    <span class="font-14">{{ item2.description }}</span>
                  </div>
                  <img :src="item2.is_active
        ? selected.data[0].uuid == item.uuid
          ? require('@/assets/images/svg/ic_chek_gray.svg')
          : require('@/assets/images/svg/ic_chek.svg')
        : require('@/assets/images/svg/ic_not_chek.svg')
      " />
                </div>
              </div>
              <div class="tcontent__button">
                <button v-if="!isActive[index]" class="font-14 btn_out_tr d_none"
                  @click="showInfo(isActive[index], index)">
                  Подробнее
                </button>
                <button v-if="isActive[index]" class="font-14 btn_out_tr d_none"
                  @click="showInfo(isActive[index], index)">
                  Скрыть
                </button>
                <button class="font-16 btn_green" @click="getTariffes(item)">
                  Выбрать тариф
                </button>
              </div>
            </div>
            <!-- <div v-if="index === 0" class="tariffes__items-block-full-logo">
              <img src="@/assets/images/svg/ic_flag.svg" alt="" />
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="tariffes__desc">
        <p class="f-12">* Ты можешь изменить или отменить подписку в любой момент</p>
        <p class="f-12">* Подписка на Feels будет автоматически возобновляться, пока ты не отменишь автопродление.</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TabBlock from "@/components/tabs/TabBlock.vue";
import TabItem from "@/components/tabs/TabItem.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    TabBlock,
    TabItem,
  },
  data() {
    return {
      isActive: [false],
      tariffesData: null,
      selected: null,
    };
  },
  mounted() {
    this.fetchTariffes();
  },
  computed: {
    ...mapGetters(["GET_IS_AUTH", "GET_USER"]),
  },
  methods: {
    ...mapMutations(["SET_MODAL_LOGIN"]),
    async fetchTariffes() {
      await axios
        .get("subscriptions/")
        .then((response) => (this.tariffesData = response.data));
    },
    getTariffes(item) {
      this.$router.push({ name: "Tariff" });
      // if (
      //   this.GET_IS_AUTH === true &&
      //   this.GET_USER?.user_status === "UNSUBSCRIBED"
      // ) {
      //   this.$router.push({ name: "Payment", params: { id: item.uuid } });
      // } else {
      //   this.SET_MODAL_LOGIN();
      //   // this.$router.push({name: 'PersonalAccount'})
      // }
      // //   this.$emit("openMenu", item);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    showInfo(value, index) {
      value = !value;
      this.isActive[index] = value;
      this.$nextTick(this.$forceUpdate);
    },
    selectTab(tab) {
      this.selected = tab;
    },
  },
  watch: {
    tariffesData() {
      this.selected = this.tariffesData[0];
    },
  },
};
</script>

<template>
  <div class="home">
    <div class="home__content">
      <Slider @scroll="scrollTo" />
      <Training />
      <Sphere />
      <Online />
      <Slider2 />
      <Form @scroll="scrollTo" />
      <Maraphone />
      <!-- <Fitnes /> -->
      <ResultGrid />
      <influence />
      <Tariffes @openMenu="openMenu" />
      <!-- <Author /> -->
      <Faq />
      <!-- <Feedback  /> -->
      <FeedbackV2 />
      <div class="header__main pay-header" :class="{ _active: isOpen }">
        <div class="pay-header__menu" v-click-out-side="hideMenu" :class="{ _active: isOpen }" ref="headerMenu">
          <div class="payContent">
            <div class="payContent__header">
              <span class="font-24">
                Оплата
              </span>
              
              <span>
                <div class="pay-header__icon icon-menu" ref="burgerMenu" @click="openMenu" :class="{
                  'menu-open': isOpen,
                }">
                  <span></span>
                </div>
              </span>
            </div>
            <div class="payContent__title">
              <span class="font-14">Выбранный тариф</span>
            </div>
            <div class="payContent__tariffes">
              <div class="payContent__tariffes-title">
              <span class="font-20" v-if="modalData.name" v-html="modalData.name"></span>
              </div>
              <div class="payContent__tariffes-text">
                <span class="font-18" v-if="modalData.price">{{formatPrice(modalData.price)}} ₸</span>
                <span class="font-14">в месяц</span>
              </div>

            </div>
            <!-- <hr /> -->
            <div class="payContent__process">
              <div class="payContent__process-title">
                <span class="font-20">
                  Введите почту
                </span>
              </div>
              <!-- <div class="payContent__process-layout">
                <step-progress :length="stepData"></step-progress>
              </div> -->
              <div class="payContent__process-tabs">
                <div class="payContent__process-tabs-list d_none m_none">
                  <TabItem :tabItems="tabs" :selected="selected" @setTab="selectTab"></TabItem>
                </div>
                <div class="payContent__process-tabs-content">

                  <TabBlock class="payTab" v-for="(item_0, index) in tabs" :key="index"
                    :isSelected="selected.id === item_0.id">
                    <div class="payTab__content">
                      <span class="font-14">
                        {{item_0.description}}
                      </span>
                      <iframe v-if="item_0.src.length" :src="item_0.src"></iframe>
                      <input v-else v-model="email" placeholder="Электронная почта" />
                      <div class="payTab__content-btn" :style="item_0.src.length ? 'justify-content: center' : ''">
                        <button v-if="item_0.src.length" class="font-16 btn_blue" @click="openMenu">Перейти на главную</button>
                        <button v-else class="font-16 btn_blue" @click="fetchPay">Далее</button>
                      </div>
                    </div>
                  </TabBlock>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from '@/components/Home/Slider.vue';
import Training from '@/components/Home/Training.vue';
import Maraphone from '@/components/Home/Maraphone.vue';
import Form from '@/components/Home/Form.vue';
import Slider2 from '@/components/Home/Slider2.vue';
import Fitnes from '@/components/Home/Fitness.vue';
import ResultGrid from '@/components/Home/ResultSlide.vue';
import Tariffes from '@/components/Home/Tariffes.vue';
import Faq from '@/components/Home/Faq.vue';
import Feedback from '@/components/Home/Feedback.vue';
import FeedbackV2 from '@/components/Home/v2Feedback.vue';

import { mapState } from "vuex";
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import stepProgress from '@/components/stepProgessSlider';
import TabItem from "@/components/tabs/TabItem2";
import TabBlock from "@/components/tabs/TabBlock";
import axios from "axios"
import Influence from '@/components/Home/Influencev2.vue';
import Author from './Author.vue';
import Sphere from '@/components/Home/Sphere.vue';
import Online from '@/components/Home/Online.vue';
export default {
  name: 'HomeView',
  directives: {
    clickOutSide,
  },
  components: {
    Slider,
    Training,
    Maraphone,
    Form,
    Slider2,
    Fitnes,
    ResultGrid,
    Tariffes,
    Faq,
    stepProgress,
    Feedback,
    TabItem,
    TabBlock,
    Influence,
    FeedbackV2,
    Author,
    Sphere,
    Online
  },
  data() {
    const basics1 = 'Почта'
    const basics2 = 'Карта'
    // const basics3 = 'Оплата'

    return {
      isOpen: false,
      isOpenDropdown: false,
      stepData: {
        length: 2,
        step: 1
      },
      selected: basics1,
      tabs: [
        {
          id: 1,
          title: basics1,
          description: "Мы отправим на вашу электронную почту логин и пароль которые нужны для входа в приложение.",
          src: "",
        },
        {
          id: 2,
          title: basics2,
          description: "Для оплаты нужны данные вашей карты.",
          src: "",
        },
        // {
        //   id: 3,
        //   title: basics3,
        //   description: "",
        //   src: "",
        // }
      ],
      email: "",
      modalData: "",
    }
  },
  mounted() {
    this.selected = this.tabs[0]
  },
  methods: {
    selectTab(tab) {
      this.selected = tab
      this.stepData.step = this.selected.id
      console.log(this.selected.id)
    },
    scrollTo(link) {
      const offsetTop = document.getElementById(link);
      document.getElementById(link).scrollIntoView({
        top: offsetTop,
        behavior: "smooth",
      });
    },
    openMenu(item) {
      this.isOpen = !this.isOpen;
      this.modalData = item
    },
    hideMenu(e) {
      const burger = this.$refs.burgerMenu[0];
      if (!(e.target == burger || burger?.contains(event.target))) {
        this.isOpen = false;
      }
    },
    fetchPay() {
      axios.post(`users/subscriptions/${this.modalData.uuid}/payment/`, {

        email: this.email

      })
        .then(
          (response) => (
            (this.tabs[1].src = response.data.url),
            (this.selected = this.tabs[1]),
            (this.stepData.step = 2)
          )
        );
    },
    formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        },
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        document.body.classList.add("_lock");
      } else {
        if (document.body.classList.contains("_lock")) {
          document.body.classList.remove("_lock");
        }
      }
    },
    // selected() {
    //   // this.fetchReport();
    // }
  },
}
</script>


<style lang="scss">
// Минимальная ширина страницы
$minWidth: 320;

// Ширина полотна (макета)
$maxWidth: 1920;

// Ширина ограничивающего контейнера (0 = нет ограничения)
$maxWidthContainer: 1279;

// Общий отступ у контейнера
// (30 = по 15px слева и справа, 0 = нет отступа)
$containerPadding: 30;

// Ширина срабатывания первого брейкпоинта
$containerWidth: $maxWidthContainer + $containerPadding;

// Брейк-поинты
$pc: $containerWidth; // ПК, ноутбуки, некоторые планшеты в горизонтальном положении
$laptop: 1366.98;
$tablet: 991.98; // Планшеты, некоторые телефоны в горизонтальном положении
$mobile: 767.98; // Телефоны L
$mobileSmall: 479.98; // Телефоны S

body {
  &._lock {
    overflow: hidden;

    &::before {
      content: "";
      z-index: 4;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      transition: opacity 0.3s ease 0s;
      background: rgba(0, 0, 0, 0.5);
    }
  }

}

.pay-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0;

  @media (max-width: $laptop + px) {
    justify-content: normal;
  }

  @media (max-width: $tablet + px) {
    justify-content: space-between;
  }

  @media (max-width: $mobile + px) {
    padding: 16px 0;
  }

  &__menu {
    z-index: 21;
    flex: 0 1 973px;
    background: #ffffff;
    overflow-y: auto!important;
    position: fixed;
    top: 0;
    right: 0;
    width: 30%;
    min-width: 500px;
    height: 100%;

    @media (max-width: 576px) {
      width: 100%;
      min-width: unset;
    }

    padding: 20px 0 0 24px;
    transition: all 0.3s ease-in;
    transform: translateX(100%);
    border-left: 1px solid #E3E3E3;

    &._active {
      transform: translateX(0);
    }
  }


  &__login {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #256ed8;

    a {
      display: flex;
      align-items: center;
    }

    &_icon {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
      }

      @media (max-width: 1182px) {
        width: 18px;
        height: 18px;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    &_text {
      margin: 0 0 0 8px;
      color: #2c3131;

      @media (max-width: $mobileSmall + px) {
        font-size: 14px;
      }
    }

    &_account {
      margin: 0 0 0 8px;

      @media (max-width: 1182px) {
        display: none;
      }
    }

  }

  &__logout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 27px;
    border-left: 1px solid #e5e5e5;
    padding: 0 0 0 16px;
    margin: 0 0 0 16px;

    button {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 17px;
      height: 20px;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 0 10px;
      padding: 0 0 0 10px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;


    display: block;

    li,
    .small-header__item {
      padding: 12px 0;
      border-bottom: 0.3px solid rgba(169, 169, 169, 0.6);
    }


    li {
      &:first-child {
        @media (min-width: $mobile + px) {
          display: none;
        }
      }
    }
  }

  &__link,
  .small-header__item {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 139%;
    letter-spacing: 0.02em;
    color: #256ed8;
    cursor: pointer;

    @media (max-width: $laptop + px) {
      font-size: 16px;
    }

    @media (max-width: $tablet + px) {
      font-size: 14px;
      line-height: 135.71%;
      color: #2c3131;
    }

    &_login {
      @media (min-width: $tablet + px) {
        display: none;
      }
    }

    &_phones {
      a {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin: 0 0 012px 0;
        }

        span {
          width: 18px;
          height: 18px;
          margin: 0 8px 0 0;
          line-height: 100%;

          img {
            vertical-align: middle;
          }
        }
      }

      @media (min-width: $mobile + px) {
        display: none;
      }
    }

    &_lang {
      @media (min-width: $mobile + px) {
        display: none;
      }
    }
  }

  &__logo {
    @media (min-width: $tablet + px) {
      margin: 0 50px 0 0;
    }

    img,
    a {
      display: block;
      object-fit: contain;
      height: 92px;
      width: 98px;

      @media (max-width: $tablet + px) {
        width: 77px;
        height: 72px;
      }

      @media (max-width: $mobileSmall + px) {
        width: 56px;
        height: 44px;
      }
    }
  }

  &__button {
    @media (max-width: $laptop + px) {
      display: none !important;
    }

    @media (max-width: $tablet + px) {
      display: block !important;
    }

    @media (max-width: $mobile + px) {
      display: none !important;
    }
  }

  &__action-icon {
    width: 24px;
    height: 24px;
    margin: 0 5px 0 0;
    text-align: center;

    img {
      vertical-align: middle;
    }

    @media (max-width: $tablet + px) {
      margin: 0 8px 0 0;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 3px 0 0;
    }
  }
}

.icon-menu {
  display: block;
  position: relative;
  min-width: 30px;
  min-height: 26px;
  width: 30px;
  height: 26px;
  cursor: pointer;
  z-index: 5;

  span,
  &::before,
  &::after {
    content: "";
    transition: all 0.35s ease-in-out;
    right: 0;
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 25px;
    background-color: #333333;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  span {
    top: calc(50% - 1px);
  }


  @media (max-width: $mobile + px) {
    width: 20px;
    height: 16px;
    min-width: 20px;
    min-height: 16px;
  }

  &.menu-open {
    span {
      background: #333333;
      transform: scale(0);
    }

    &::before {
      background: #333333;
      transform: rotate(45deg);
      top: calc(50% - 1px);
    }

    &::after {
      background: #333333;
      transform: rotate(-45deg);
      bottom: calc(50% - 1px);
    }
  }
}

.small-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;

  @media (max-width: $laptop + px) {
    padding: 20px 0 10px 0;
  }

  @media (max-width: $tablet + px) {
    padding: 15px 0 0 0;
  }

  @media (max-width: $mobile + px) {
    display: none;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    align-items: center;

    li {
      &:not(:last-child) {
        margin: 0 32px 0 0;

        @media (max-width: $tablet + px) {
          margin: 0 20px 0 0;
        }
      }

      @media (max-width: $mobile + px) {
        display: none;
      }
    }
  }

  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 112.5%;
    letter-spacing: 0.02em;
    color: #626262;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    @media (max-width: $tablet + px) {
      font-size: 14px;
    }
  }

  &__icon {
    width: 18px;
    height: 18px;
    margin: 0 8px 0 0;
    line-height: 100%;

    img {
      vertical-align: middle;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__login {
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #256ed8;

    a {
      display: flex;
      align-items: center;
    }

    &_icon {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
      }

      @media (max-width: 1182px) {
        width: 18px;
        height: 18px;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    &_text {
      margin: 0 0 0 8px;
    }

    &_account {
      margin: 0 0 0 8px;


    }
  }

  &__logout {
    height: 27px;
    border-left: 1px solid #e5e5e5;
    padding: 0 0 0 16px;
    margin: 0 0 0 16px;

    button {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 17px;
      height: 20px;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 0 10px;
      padding: 0 0 0 10px;
    }
  }

  &__select,
  &__login {
    font-weight: 400;
    font-size: 16px;
    white-space: nowrap;
    //line-height: 137.5%;
    letter-spacing: 0.02em;
    color: #626262;

    @media (max-width: $mobileSmall+ px) {
      font-size: 14px;
    }
  }

  &__select {
    padding: 0 16px 0 0;
    margin: 0 16px 0 0;
    border-right: 1px solid #e5e5e5;

    @media (max-width: $tablet + px) {
      padding: 0 8px 0 0;
      margin: 0 16px 0 0;
    }

    @media (max-width: $mobile+ px) {
      border: none;
      padding: 0;
      margin: 20px 24px 0 0;
    }
  }

  &__lang {
    display: inline-flex;
    align-items: center;
    border-radius: 5px;

    &:not(:last-child) {
      margin: 0 24px 0 0;
    }

    @media (max-width: 767.98px) {
      padding: 5px 8px;

      &_.active,
      &:first-child {
        background: #f4f4f4;
      }
    }
  }

  &__action-icon {
    width: 24px;
    height: 24px;
    margin: 0 5px 0 0;
    text-align: center;

    img {
      vertical-align: middle;
    }

    @media (max-width: $tablet + px) {
      margin: 0 8px 0 0;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 3px 0 0;
    }
  }

  &__social {
    @media (max-width: 1000px) {
      display: none;
    }
  }
}
</style>

<template>
  <div class="slider">
    <div class="slider__content">
      <!-- <img class="d__none" src="@/assets/images/png/newmainbanner.png" alt="asd" />
            <img class="m__none" src="../../assets/images/png/newmainbannermobile.png" alt="asd" /> -->
      <div class="slider__items container">
        <div class="slider__items-row">
          <div class="slider__items-row-col1">
            <div class="slider__content-text">
              <span class="font-48"
                >Стройное тело в энергии и в легкости</span>
                <img class="m__none" src="@/assets/images/svg/mainBannerV2.svg" alt="">
              <p class="font-20">
                Уже более 40.000 девушек прошли авторскую программу по гармоничному развитию своего тела и сознания
              </p>
              <!-- <p class="font-20">Удобная онлайн платформа для тренировок</p>
              <p class="font-20">Фигура мечты без жестких диет и запретов</p> -->
            </div>
            <div class="slider__content-buttom">
              <span class="font-20">Попробуй первую тренировку за 0 ₸</span>
              <!-- @click="scrollToSection(links[0].id)" -->
              <router-link
                class="font-16 btn_blue"
                :to="{name: 'Tariff'}"
              >
                {{ links[0].name }}
              </router-link>
              <p class="font-16">Без оплаты, привязки карты и лишних беспокойств</p>
            </div>
          </div>
          <div class="slider__items-row-col2 d__none">
            <img src="@/assets/images/png/bannerMainV2.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [{ name: "ПРИСОЕДИНИТЬСЯ", id: "tariffes" }],
    };
  },
  methods: {
    scrollToSection(link) {
      this.$emit("scroll", link);
    },
  },
};
</script>

<style></style>

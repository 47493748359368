<template>
  <div class="tab-items">
    <ul class="tab-list">
      <li v-for="tab in tabItems" :key="tab" class="tab-item">
        <div :class="{active: tab === selected}" class="tab-link font-16" 
        @click="setTab(tab)
        ">{{ tab.title }}</div>
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  props: ['tabItems','selected'],
  methods: {
    setTab(tab){

        this.$emit('setTab',tab)
      
    }
  }
}
</script>

<style scoped lang="scss">

.tab-items{
  position: relative;
}
.tab-item{
  width: 100%;
  &:nth-last-child(-n+1){
    .tab-link{
      border-radius: 0px 16px 16px 0px;
    }
  }
  &:nth-child(1){
    .tab-link{
      border-radius: 16px 0px 0px 16px;
    }
  }
}
.tab-list{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  max-width: 100%;
  border-radius: 16px;
  border: 1px solid #E9E9E9;
  justify-content: center;
  // overflow: scroll;
}

.tab-link{
  cursor: pointer;
  background: #E9E9E9;
  text-align: center;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  padding: 10px 33px;
  color: #858585;
  font-weight: 500;
  &:hover, &.active{
    color: #FFFFFF;
    background: #1985A1;
    // border-bottom: 1px solid #3252C6;
    // background: #00E191;
  }
}
@media (max-width: 576px) {
//   .tab-list{
//   flex-direction: column;
//   align-items: flex-start;
  
// }
.tab-item{
    // margin: 1% 0;
  }
}
</style>
<template>
  <div class="form">
    <div class="form__content container">
        <div class="form__items" v-for="(link,index) in links" :key="index">
            <span class="font-40 uppercase">Занимайся в любое<br/> время, в любом месте</span>
            <span class="font-20">Оформи подписку и получи мгновенный доступ ко всем<br/> программам и тренировкам</span>
            <router-link :to="{name: 'Tariff'}" class="font-16 btn_blue" >{{link.name}}</router-link>
            <!-- @click="scrollToSection(link.id)" -->
        </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return{ 
      links: [
        {name: 'НАЧАТЬ ТРЕНИРОВАТЬСЯ', id :'feedback' }
      ]
    }
  },
  methods: {
    scrollToSection(link) {
      this.$emit("scroll", link);
    },
  }
}
</script>

<style>

</style>
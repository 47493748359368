<template>
  <div class="footer">
    <div class="footer__content container">
      <div class="footer__items">
        <div class="footer__items-top">
          <div class="footer__items-top-logo">
            <img src="@/assets/images/svg/feels_logo.svg" alt="" />
          </div>
          <div class="footer__items-top-contacts">
            <div
              class="footer__items-top-contacts-list"
              v-for="(item, idx) in contacts"
              :key="idx"
            >
              <span>{{ item.title }}</span>
              <a target="_blank" :href="item.click">{{ item.name }}</a>
            </div>
          </div>
          <div class="footer__items-top-app">
            <span><img src="@/assets/images/svg/mastercard.svg" alt="" /></span>
            <a
              href="https://apps.apple.com/kz/app/feels/id1641239205"
              target="_blank"
              ><img src="@/assets/images/svg/ios.svg" alt=""
            /></a>
            <a
              href="https://play.google.com/store/apps/details?id=kz.feels&hl=ru"
              target="_blank"
              ><img src="@/assets/images/svg/google-play.svg"
            /></a>
          </div>
        </div>
        <div class="footer__items-bottom">
          <div class="footer__items-bottom-left">
            <p class="font-16">© 2024 Feels. Все права защищены</p>
            <p class="font-12">
              Услуги, контент и продукты нашего веб-сайта и приложения
              предназначены исключительно для информационных целей. Feels не
              предоставляет медицинских консультаций, диагностики или лечения.
            </p>
          </div>
          <div class="footer__items-bottom-right">
            <router-link :to="{ name: 'public_offer' }"
              >Договор оферты</router-link
            >
            <router-link :to="{ name: 'confidential' }"
              >Политика конфиденциальности</router-link
            >
            <router-link :to="{ name: 'payment' }">Оплата</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    contacts: [
      {
        title: "Телефон:",
        name: "8 705 916 60 93",
        click: "tel:+77059166093",
      },
      {
        title: "e-mail:",
        name: "info@feels.kz",
        click: "mailto:info@feels.kz",
      },
      {
        title: "Адрес:",
        name: "Алматы, Казахстан",
        click: "https://goo.gl/maps/4FCXPrS6mvpg4uA79",
      },
      {
        title: "Instagram:",
        name: "@feels.kz",
        click: "https://www.instagram.com/feels.kz/",
      },
    ],
  }),
};
</script>

<style></style>

<template>
  <div class="confAlert">
    <div v-if="modalText" class="confAlert__items">
      <div class="confAlert__items-icon">
        <img v-if="modalText.success" src="@/assets/images/svg/Success.svg" />
        <img v-if="modalText.success === false" src="@/assets/images/svg/error.svg" />

      </div>
      <div class="confAlert__items-title">
        <h2 class="font-20" v-html="modalText.cTitle"></h2>
      </div>
      <div v-if="modalText.cDesc" class="confAlert__items-desc">
        <h2 class="font-16" v-html="modalText.cDesc"></h2>
      </div>
      <div v-if="modalText.app" class="confAlert__items-market">
        <a href="https://apps.apple.com/kz/app/feels/id1641239205" target="_blank"><img src="@/assets/images/svg/ios.svg"
            alt="" /></a>
        <a href="https://play.google.com/store/apps/details?id=kz.feels&hl=ru" target="_blank"><img
            src="@/assets/images/svg/google-play.svg" /></a>
      </div>
      <!-- <div class="confAlert__items-btns">
           <button class="font-16 btn_blue" @click="$emit('confirm')" v-if="modalText.yes">{{ modalText.yes }}</button>
            <button class="font-16 btn_link_red" @click="$emit('decline')" v-if="modalText.no">{{ modalText.no }}</button>
        </div> -->

    </div>
  </div>
</template>

<script>
export default {
  props: ["modalText"],
  data: () => ({}),
};
</script>

<style scoped lang="scss">
.confAlert {
  &__items {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 16px;

    &-title {
      // margin: 0 40px 40px 40px;
      text-align: center;

      .font-20 {
        color: #25282A;
        font-weight: 700;
      }

      @media (max-width: 576px) {
        margin: 0;

        .font-20 {
          font-size: 18px !important;

        }
      }
    }

    &-desc {
      color: var(--light-text, #25282A);
      text-align: center;
      font-size: 16px;
      font-family: "Circe";
      font-weight: 400;
    }

    &-market {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 65px;

      @media (max-width: 576px) {
        flex-direction: column;
      }

      a {

        img {
          width: 140px;
          height: 40px;
        }
      }
    }
  }
}
</style>

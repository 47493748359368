<template>
  <div class="feedback" id="feedback">
    <div class="feedback__content container">
        <div class="feedback__items">
            <div class="feedback__items-row row">
                <div class="feedback__items-row-col1 col">
                    <span class="font-28">Боитесь, что марафон не подойдет вам?<br />Попробуйте бесплатно!</span>
<span class="font-16">Заполните простую форму, и мы пришлем вам доступ к первой тренировке. </span>
                </div>
                <div class="feedback__items-row-col2 col">
                    <input placeholder="Ваше имя" class="input_wht" />
                    <input placeholder="Ваша почта" class="input_wht" />
                    <input placeholder="Ваш номер телефона" class="input_wht" />
                    <p class="font-14">Отправляя данные вы принимаете условия пользования</p>
                    <button class="btn_blue font-16-14">Получить тренировку бесплатно</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
<template>
  <div class="training" id="feels">
    <div class="training__content container">
        <div class="training__items row">
            <div class="training__items-icon col">
                <img src="@/assets/images/png/phone_logo_v8.png" />
            </div>
            <div class="training__items-text col">
                <span class="font-32">Тренировки онлайн - единая подписка на все!</span>
                <p class="font-20"><img src="@/assets/images/svg/ic_program_1.svg" /> Тренировки, рационы питания с удобной корзиной продуктов</p>
                <p class="font-20"><img src="@/assets/images/svg/ic_chart.svg" /> Разнообразные видеоуроки и медитации</p>
                <p class="font-20"><img src="@/assets/images/svg/ic_cub.svg" /> Отслеживание прогресса и трекер воды</p>
                <p class="font-20"><img src="@/assets/images/svg/ic_fire.svg" /> Постоянная поддержка и мотивация</p>
                <router-link :to="{name: 'Tariff'}" class="font-16 btn_blue">НАЧАТЬ ТРЕНИРОВАТЬСЯ</router-link>
            </div>
            <div class="training__items-app">
              <a href="#"><img src="@/assets/images/svg/appstore.svg" alt="" /></a>
              <a href="#"><img src="@/assets/images/svg/googleplay.svg" alt="" /></a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
<template>
  <div style="background-color: rgba(232, 243, 246, 0.4)">
    <div class="author">
      <div class="author__content">
        <div class="author__items">
          <div class="author__items-title container">
            <h1 class="font-32">Автор методики наших программ</h1>
            <h3 class="font-24">Богенбаева Нурбота</h3>
          </div>
          <div class="author__items-block">
            <ul class="container">
                <li v-for="(item,idx) in metodics"  :key="'a'+idx">
                    <img :src="item.icon" alt="">
                    <p class="font-18" v-html="item.title"></p>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            metodics:[
                {
                    icon: require('@/assets/images/svg/author1.svg'),
                    title: "Ее авторские программы прошли более 40.000 женщин по всему миру"
                },
                {
                    icon: require('@/assets/images/svg/author2.svg'),
                    title: "Сертифицированный фитнес тренер, коуч, EMS и Stretching тренер"
                },
                {
                    icon: require('@/assets/images/svg/author3.svg'),
                    title: "5-и кратная чемпионка РК по легкой атлетике"
                },
                {
                    icon: require('@/assets/images/svg/author4.svg'),
                    title: "12 лет опыта в спорте и фитнесе"
                },
                {
                    icon: require('@/assets/images/svg/author5.svg'),
                    title: "3-х кратная чемпионка центральной Азии по легкой атлетике"
                },
                {
                    icon: require('@/assets/images/svg/author6.svg'),
                    title: "Лидер сферы фитнеса в Казахстане"
                },
            ]
        }
    }
}
</script>

<style></style>

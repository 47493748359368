<template>
  <div class="slinf">
    <div class="slinf__content">
      <div class="slinf__items">
        <div class="slinf__items-title">
          <div class="container">
            <span class="font-32">Отзывы о Feels</span>
            <div class="slinf__items__navigation">
              <div class="swiper-button-next selectornx14"></div>
              <div class="swiper-button-prev selectorpr14"></div>
            </div>
          </div>
        </div>
        <swiper
          :breakpoints="breakpoints"
          :centeredSlides="true"
          :loop="true"
          :modules="modules"
          :slides-per-view="3"
          :space-between="20"
          :navigation="navigation0"
          class="slinf__items-swiper inslinfl"
        >
          <swiper-slide
            class="inslinfl__items"
            v-for="item in includes"
            :key="item"
          >
            <div class="inslinfl__items-top">
              <div class="inslinfl__items-icon">
                <img :src="item.img" alt="" />
              </div>
              <div class="inslinfl__items-text">
                <span
                  class="inslinfl__items-text-title font-22"
                  v-html="item.title"
                ></span>
                <span
                  class="inslinfl__items-text-desc font-16"
                  v-html="item.desc"
                >
                </span>
              </div>
            </div>
            <div class="inslinfl__items-bottom">
              <p class="font-16" v-html="item.text"></p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      includes: [
        {
          id: 1,
          img: require("@/assets/images/png/onf1.png"),
          title: "Данара Жунусова",
          desc: "@zhunussovna",
          text: "Хочу поблагодарить Нурбу и всю команду FEELS, которая работала над этим приложением год. Здесь настолько все удобно, качественные видео, звук, картинка не оторвать глаз. Тренируешься и получаешь эстетическое удовольствие. А еще все тренировки делать легко и быстро. Буду заниматься только по Нурбиным тренировкам, так как они дают 100% результат, не говоря уже о вебинарах и медитациях, это просто бомба 💣",
        },
        {
          id: 2,
          img: require("@/assets/images/png/onf2.png"),
          title: "Инди Тыржанова",
          desc: "@indi_tyrzhanova",
          text: "Занимаюсь с Нурбой уже почти год и никогда до этого я не любила спорт, потому что всегда все было из принуждения самой себя, но не из любви и заботы. Благодаря вебинарам я поняла, что просто хочу быть здоровее и делать лучше только для себя. Большое спасибо feels за то, что вы есть и за все крутые бонусы, которые пришли в мою жизнь: в виде любви к себе, заботы о себе и здорового тела💫",
        },
        {
          id: 3,
          img: require("@/assets/images/png/onf3.png"),
          title: "Камилла Кашаганова",
          desc: "@kamillailhan",
          text: "Нашла страничку Нурбы. Тренировки с Нурбой стали моей терапией, так как у меня уже был негативный опыт в спорте. Я даже представить себе не могла, что можно заниматься 30 минут 4 раза в неделю и за месяц получить тот результат, для которого раньше я целый день убивалась в зале. Познания и принятие себя – это длительное и увлекательное путешествия. ❤️",
        },
        {
          id: 4,
          img: require("@/assets/images/png/onf4.png"),
          title: "Инди Муссина",
          desc: "@indidance_",
          text: "Благодарю за идеальное приложение 😻 И команду за поддержку и мотивацию, которая помогла мне поверить в себя и достичь своих целей! 🚀",
        },
      ],
      navigation0: {
        nextEl: ".selectornx14",
        prevEl: ".selectorpr14",
      },
      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 1,
        },
        // when window width is >= 480px
        655: {
          slidesPerView: 2.1,
          spaceBetween: 5,
        },
        // when window width is >= 640px
        1000: {
          slidesPerView: 3.4,
        },
        1300: {
          slidesPerView: 4.4,
          // spaceBetween: 20
        },
      },
    };
  },
};
</script>

<style></style>

<template>
  <HeaderVue @scroll="scrollTo" />
  <router-view />
  <Footer />
  <modalv3Vue :closeShow="true" @close="closeModal()" :open="modalTextOpened">
    <ModalText :modalText="modalText" />
  </modalv3Vue>
  <modal :open="openLogin" @close="btnLogin()">
    <Authorization />
  </modal>
  <div v-if="loader" class="loader">
    <div class="load-container">
      <div class="linespinner"></div>
    </div>
  </div>
</template>
<script>
import HeaderVue from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import modal from "./components/modal/modal.vue";
import Authorization from "./views/Authorization.vue";
import ModalText from "@/components/modal/modalText.vue";
import modalv3Vue from "@/components/modal/modalv3.vue";

export default {
  data() {
    return {
      modalTextOpened: false,
      modalText: {
        cTitle: "Поздравляем! Ваша оплата прошла успешно.",
        cDesc: "Для того, чтобы приступить к тренировкам, скачайте приложение Feels",
        success: true,
        app: false
      },
    }
  },
  components: {
    HeaderVue,
    Footer,
    modal,
    Authorization,
    modalv3Vue,
    ModalText
  },
  created() {
    setTimeout(() => {
      if (this.$route.query.type == 'payment') {
        if (this.$route.query.success == 'True') {
          this.modalText.app = true
          this.modalTextOpened = true
        } else {
          this.modalText.success = false
          this.modalText.cTitle = "Произошла ошибка";
          this.modalText.cDesc = this.$route.query.message;
          this.modalTextOpened = true
        }
      } else if(this.$route.query.type == 'card') {
        if (this.$route.query.success == 'True') {
          this.modalText.cTitle = "Карта добавлена!";
          this.modalText.cDesc = "";
          this.modalText.success = true
          this.modalTextOpened = true

        } else {
          this.modalText.success = false
          this.modalText.cTitle = "Произошла ошибка";
          this.modalText.cDesc = this.$route.query.message;
          this.modalTextOpened = true
        }
      }
    }, 500);

  },
  computed: {
    ...mapGetters({ openLogin: "GET_MODAL_LOGIN", loader: 'GET_LOADER' }),
  },
  methods: {
    ...mapMutations(["SET_MODAL_LOGIN"]),
    scrollTo(link) {
      const offsetTop = document.getElementById(link);
      document.getElementById(link).scrollIntoView({
        top: offsetTop,
        behavior: "smooth",
      });
    },
    btnLogin() {
      this.SET_MODAL_LOGIN();
    },
    closeModal() {
      this.$router.replace({ query: {} });
      this.modalTextOpened = false
    }
  },
};
</script>
<style lang="scss">
.loader {
  position: fixed;
  z-index: 999 !important;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.load-container {
  position: relative;
  display: inline-block;
  width: 130px;
  height: 130px;
  overflow: hidden;
  letter-spacing: normal;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.linespinner {
  width: 10px;
  height: 30px;
  background: #579695;
  position: relative;
  animation: linespinner 1s ease-in-out infinite;
  animation-delay: 0.4s;
  border-radius: 8px;
}

.linespinner:after,
.linespinner:before {
  border-radius: 8px;
  content: "";
  position: absolute;
  width: 10px;
  height: 30px;
  background: #579695;
  animation: linespinner 1s ease-in-out infinite;
}

.linespinner:before {
  right: 18px;
  animation-delay: 0.15s;
}

.linespinner:after {
  left: 18px;
  animation-delay: 0.6s;
}

@keyframes linespinner {

  0%,
  100% {
    background-color: #579695;
    box-shadow: 0 0 0 #579695, 0 0 0 #579695;
  }

  50% {
    background-color: #1985A1;
    box-shadow: 0 -15px 0 #1985A1, 0 15px 0 #1985A1;
  }
}
</style>

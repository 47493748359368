import { createStore } from 'vuex'
import axios from "axios";
import { useToast } from "vue-toastification";
import router from "../router";
const toast = useToast();

export default createStore({
  state: {
    lang: localStorage.getItem("lang") || "ru",
    modalLogin: false,
    isAuth: false,
    unAuth: false,
    userInfo: null,
    cards: null,
    loader: false,
    
  },
  getters: {
    GET_MODAL_LOGIN: (state) => state.modalLogin,
    GET_UN_AUTH: (state) => state.unAuth,
    GET_IS_AUTH: (state) => state.isAuth,
    GET_USER: (state) => state.userInfo, 
    GET_CARDS: (state) => state.cards,
    GET_LOADER: (state) => state.loader,
  },
  mutations: {
    SET_MODAL_LOGIN(state) {
      state.modalLogin = !state.modalLogin
    },
    SET_AUTH(state, isAuth) {
      state.isAuth = isAuth
    },
    SET_UN_AUTH(state, unAuth) {
      state.unAuth = unAuth
    },
    SET_USER(state, userInfo) {
      state.userInfo = userInfo
    },
    SET_CARDS(state, cards) {
      state.cards = cards
    },
    SET_LOADER(state) {
      state.loader = !state.loader
    },
    SET_LOADER_FALSE(state) {
      state.loader = false
    }
  },
  actions: {
    CHECK_AUTH({ commit }) {
      if ($cookies.get("token")) {
        // commit("SET_AUTH", true);
        // commit("FETCH_USER")
        this.dispatch("FETCH_USER")
      }
    },
    async FETCH_USER({ commit }) {
      try {
        const data = await axios.get("users/me/", {
          headers: {
            Authorization: `Bearer ${$cookies.get("token")}`,
          },
        });
       if(data.status === 200){
        await commit("SET_USER", data.data);
        commit("SET_UN_AUTH", false);
        commit("SET_AUTH", true);
       }
      } catch (error) {
        console.log(error);
        if(error.response.status === 401){
          commit("SET_UN_AUTH", true);
          commit("SET_AUTH", false);
          $cookies.remove("token");
          router.push({ name: "home" });

        }
      }
    },
    LOGOUT({ commit }) {
      router.push({ name: "home" });
      commit("SET_AUTH", false);
      $cookies.remove("token");
      // localStorage.setItem('Loader', true)
      // commit("SET_LOADER")
    },
    async FETCH_CARDS({ commit }) {
      try {
        const data = await axios.get("payments/cards/", {
          headers: {
            Authorization: `Bearer ${$cookies.get("token")}`,
          },
        });
        if (data.status === 200) {
          commit("SET_CARDS", data.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  modules: {
  }
})

<template>
  <div class="header">
    <div class="header__content container">
      <div class="header__items">
        <div @click="toHome" class="header__items-logo">
          <img src="@/assets/images/svg/feels_logo.svg" alt="" />
        </div>
        <div v-if="$route.name === 'home'" class="header__items-menu">
          <ul>
            <li v-for="link in headerLinks" :key="link">
              <router-link v-if="link.route.length > 0" :to="link.route"  class="font-18">{{
                link.name
              }}</router-link>
              <span v-else class="font-18" @click="scrollToSection(link.id)">{{
                link.name
              }}</span>
            </li>
          </ul>
        </div>
        <div class="header__items-login">
          <div class="header__items-login-isAuth" v-if="auth">
            <button @click="showInfo(open[0], 0)">
              <img v-if="userData.photo" :src="userData.photo" alt="" />
              <img v-else src="@/assets/images/svg/noiconimg.svg" alt="" />
              <p class="font-16">
                {{ userData.first_name ? userData.first_name : "Нет имени" }}
              </p>
              <span v-if="open[0]">
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 7L7 1L13 7"
                    stroke="#858585"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span v-else
                ><svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 1L7 7L1 1"
                    stroke="#858585"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </button>
            <dropdownVue
              class="header__items-login-dropdown-list"
              :open="open[0]"
              :class="{ databody__active: open[0] }"
            >
              <div
                class="header__items-login-dropdown-list-all font-16"
                v-for="(link, idx) in personalLinks"
                :key="idx"
              >
                <button @click.prevent="toPage(link)">
                  <p>{{ link.text }}</p>
                </button>
              </div>
            </dropdownVue>
          </div>

          <button v-else @click="toLogin" class="font-18">Войти</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import dropdownVue from "@/components/dropdown";
export default {
  components: {
    dropdownVue,
  },
  data() {
    return {
      headerLinks: [
        { name: "Программы", id: "programs", route: "" },
        { name: "О feels", id: "feels", route: "" },
        { name: "Подписка", id: "auth", route: "" },
        { name: "Тарифы", id: "tariffes", route: "tariff" },
        { name: "FAQ", id: "faq", route: "" },
      ],
      open: [false],
      personalLinks: [
        {
          text: "Профиль",
          route: "PersonalAccount",
        },
        {
          text: "Выйти",
          route: "logout",
        },
      ],
    };
  },
  created() {
    if (this.$route.name !== "home") this.CHECK_AUTH();
  },
  computed: {
    ...mapGetters({ auth: "GET_IS_AUTH", userData: "GET_USER" }),
  },
  methods: {
    ...mapActions(["CHECK_AUTH", "LOGOUT"]),
    ...mapMutations(["SET_MODAL_LOGIN"]),
    scrollToSection(link) {
      this.$emit("scroll", link);
    },
    toHome() {
      this.$router.push({ name: "home" });
    },
    toLogin() {
      this.SET_MODAL_LOGIN();
    },
    toCabinet() {
      this.$router.push({ name: "PersonalAccount" });
    },
    showInfo(value, index) {
      value = !value;
      this.open[index] = value;
      this.$nextTick(this.$forceUpdate);
    },
    toPage(value) {
      if (value.route === "logout") {
        this.open[0] = false;
        this.LOGOUT();
      } else {
        this.open[0] = false;
        this.$router.push({ name: value.route });
      }
    },
  },
};
</script>
